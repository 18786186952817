.header {
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 2px solid #272727;
}

.card-tappy {
  width: 100%;
  max-width: 400px;
  height: 250px;
  position: relative;
}

.card-white {
  border: 2px solid #eaeaea;
  background-color: #eaeaea;
}

.card-black {
  box-shadow: 0 0 10px black;
  border: 2px solid black;
  background-color: black;
}
