@import "shop.css";
@import "loader.css";
@import "on-the-card.scss";

body {
  font-family: "BeVietnam", sans-serif;
}

.main {
  min-height: 100vh;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
}

button {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  border: none;
  outline: none;
}

.btn-primary {
  color: white;
  border: 2px solid green;
}

.text-green {
  color: green;
}

.btn-primary:hover {
  background-color: green;
  color: white;
}

.btn-secondary {
  border: 1px solid lightgray;
}

.btn-secondary:hover {
  background-color: lightgray;
  color: black;
}

.border-t-3 {
  border-top-width: 3px;
}

.btn-add-contact:hover {
}

@media (max-width: 768px) {
  .price {
    display: block;
  }
}
