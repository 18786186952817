.loading-data {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background: black;
  opacity: 0.7;
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid green; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  top: 40%;
  left: 45%;
  position: absolute;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
