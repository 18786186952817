@font-face {
  font-family: "BeVietnam";
  src: url("../fonts/BeVietnam-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "BeVietnam";
  src: url("../fonts/BeVietnam-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

body {
  color: #1d1d1f;
  font-family: "BeVietnam", sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 600;
}

@media (min-width: 1200px) {
  .container {
    width: 1100px !important;
  }
}

// .font-bold {
//   font-weight: 900;
// }

.text-logo {
  color: #1d1d1f;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}

#site-header {
  background: #f6f6f6;
}

#site-header .header-navbar-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.navbar-mainmenu {
  ul {
    display: flex;
    list-style: none;
    padding: 0;
  }
}

.btn-buy a {
  display: block;
  padding: 6px 22px;
  border-radius: 30px;
  background: #2960ed;
  color: #fff;
}
.btn-datmua {
  display: block;
  padding: 10px 35px;
  border-radius: 30px;
  background: #2960ed;
  color: #fff;
}

#nav ul li a {
  color: #1d1d1f;
  padding: 10px 25px;
  display: block;
  font-size: 14px;
}

.menu-nav {
  nav li {
    padding: 15px 25px;
    text-align: center;
    font-size: 24px;
  }
}

#main-slider {
  background-color: #f6f6f6;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-body {
  h2 {
    font-weight: 900;
  }
}

#site-header.sticky-header {
  position: fixed !important;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 999;
  // animation: fadeInDown 0.5s ease-out;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translatey(-10px);
    -moz-transform: translatey(-10px);
    -o-transform: translatey(-10px);
    transform: translatey(-10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatey(0);
    -moz-transform: translatey(0);
    -o-transform: translatey(0);
    transform: translatey(0);
  }
}

::-webkit-input-placeholder {
  /* Edge */
  color: gray;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: gray;
}

::placeholder {
  color: gray;
}

.white-card {
  box-shadow: 0 0 10px #dfdfdf;
  width: 35px;
  height: 35px;
  background-color: white;
}
.black-card {
  width: 35px;
  height: 35px;
  background-color: black;
}

.card-type {
  box-shadow: 0 0 10px #dfdfdf;
  width: 45px;
  height: 30px;
  background-color: white;
  img {
    &:hover {
      box-shadow: "0px 7px 10px #1a202c";
    }
  }
}

.btn-contac-us {
  border: 1px solid #2960ed;
  padding: 8px 50px;
  &:hover {
    background-color: #2960ed;
    color: white;
  }
}

section.text-white {
  div {
    color: white;
  }
}

//
.menu-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  z-index: 5;
  background-color: rgba(255, 255, 255, 1);
  transition: height 0.3s ease-in-out;
  overflow: hidden;
  width: 100vw;

  &.active {
    height: 100vh;
  }
}

.line {
  border-top: 3px solid #e2e8f0;
  width: 150px;
  margin: auto;
}
